.footer-container {
  position: relative;
}
.footer-container > hr {
  border: 1px solid var(--lightgray);
}
.footer {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.social-logo {
  display: flex;
  gap: 4rem;
  top: 4rem;
}
.social-logo > img {
  width: 2rem;
  height: 2rem;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}
.social-logo > img:hover {
  transform: scale(1.1);
}
.logo-f > img {
  width: 10rem;
}
.footer-container > div:nth-child(3) {
  background: red;
  width: 23rem;
  height: 12rem;
  bottom: 0;
  right: 15%;
  position: absolute;
  z-index: -9;
}

.programs-title {
  display: flex;
  justify-content: space-around;
}
.programs {
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  gap: 2rem;
}
.program-type {
  display: flex;
  gap: 1rem;
}
.rightArrow {
  width: 1rem;
}
.join-now {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.category:hover {
  background: linear-gradient(210.49deg, #fa5042 1.14%, #ffa739 100.75%);
  transform: scale(1.1);
}
.category {
  transition: transform 0.45s, -webkit-transform 0.45s;
}
.category {
  background-color: gray;
  display: flex;

  color: white;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
}
.category > span:nth-child(2) {
  font-size: 1rem;
  font-weight: 700;
}
.category > img {
  display: flex;
  width: 2rem;
  fill: white;
  flex-direction: column;
  height: 2rem;
}
.programs-title > span:nth-of-type(1) {
  color: transparent;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3rem;
  text-transform: uppercase;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 1px;
}
.programs-title > span:nth-of-type(2) {
  color: white;
  font-weight: 700;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3rem;
  text-transform: uppercase;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 1px;
}
.programs-title > span:nth-of-type(3) {
  color: transparent;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3rem;
  text-transform: uppercase;
  -webkit-text-stroke-color: white;
  -webkit-text-stroke-width: 1px;
}

@media screen and (max-width: 767px) {
  .programs-title {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 12rem;
  }
  .program-type {
    flex-direction: column;
  }
}

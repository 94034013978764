.choose-left {
  grid-gap: 1rem;
  display: grid;
  flex: 1/1;
  gap: 1rem;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
}
.choose {
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
}
.gridImage1 {
  grid-row: 1/3;
  height: 28rem;
  width: 12rem;
}
.gridImage2 {
  grid-column: 2/4;
  height: 16rem;
  width: auto;
}
.gridImage3 {
  grid-row: 2/3;
  height: 11rem;
  width: 12rem;
}
.gridImage4 {
  grid-row: 2/3;
  height: 11rem;
  width: 12rem;
}
.choose-right {
  display: flex;
  flex: 1 1;
  gap: 1rem;
  flex-direction: column;
  text-transform: uppercase;
}
.choose-right > span:nth-child(1) {
  font-weight: 700;
  color: #f48816;
}
.choose-right > div {
  font-weight: 700;
  font-size: 3rem;
  color: #fff;
}
.right-details > div {
  font-size: 1rem;
}
.right-details {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
.partner {
  color: grey;
  font-size: 1rem;
  font-weight: 700;
}
.partnerImage {
  display: flex;
  gap: 1rem;
}
.partnerImage > img {
  width: 2.5rem;
}
.right-details > div > img {
  height: 2rem;
  width: 2rem;
}
.right-details > div {
  display: flex;
  gap: 1rem;
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  .choose {
    flex-direction: column;
  }
  .choose-left {
    grid-auto-rows: auto;
    overflow: hidden;
  }
  .gridImage1 {
    height: 17rem;
    width: 7rem;
  }
  .gridImage2 {
    height: 10rem;
    width: 10rem;
  }
  .gridImage3 {
    height: 6rem;
    width: 4rem;
  }
  .gridImage4 {
    height: 6rem;
    width: 4rem;
  }
  .choose-right > div {
    font-weight: 700;
    font-size: 2rem;
    color: #fff;
  }
}

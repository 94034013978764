.header {
  display: flex;
  justify-content: space-between;
}
.Logo {
  width: 10rem;
  height: 3rem;
  cursor: pointer;
}
.header-menu {
  display: flex;
  list-style: none;
  gap: 2rem;
  color: white;
}
.header-menu li {
  cursor: pointer;
}
.header-menu li:hover {
  cursor: pointer;
  color: var(--orange);
}
